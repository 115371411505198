.HubInvitePageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    margin-top: 30vh;
}

.HubInviteDivContainer {
    padding-bottom: 5vh;
    width: 50vw;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-align: center;

    p:nth-child(2) {
        color: #4D66F3;
    }

}

.joinHubBtn {
    width: 10vw;
    height: 5vh 5vw;
    padding: 0.5vh 1vw;
    background-color: #4D66F3;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    font-family: "Roboto";
    cursor: pointer;
}

.inviteLinkCopied {
    height: 2.5vh;
    padding: 1vh 1vw;
    background-color: #4df39a;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    font-family: "Roboto";
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}



.hubInvitefriendsLogo {
    width: 10vw;
    height: 10vw;
    margin-top: 5vh;
}

.joinHubImg {
    width: 20vw;
    margin-top: 2.5vh;
}

.HubInviteUserContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vh;
}

.HubInviteUserContainer div {
    width: 5vw;
    height: 5vw;
    border-radius: 5vw;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}



@media only screen and (max-width: 600px) {

    .HubInvitePageContainer {
        margin-top: -5vh;
    }

    .HubInviteUserContainer div {
        width: 10vw;
        height: 10vw;
        border-radius: 10vw;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }

    .HubInviteDivContainer {
        padding-bottom: 5vh;
        margin-top: 15vh;
        width: 80vw;
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }


    .hubInvitefriendsLogo {}

    .joinHubBtn {
        border: 1px solid #4D66F3;
        width: auto;
    }

    .joinHubImg {
        width: 50vw;
    }

}