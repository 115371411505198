header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;

    /* Set background color to white */
    h2 {
        color: #4D66F3;
    }

    >a {
        text-decoration: none;

    }
}

.logoAndTitleContainer {
    display: flex;
    align-items: center;
}

.leisureTimeLogo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.headerButtons {
    display: flex;
    gap: 10px;
}

.headerButtons a {
    text-decoration: none;
    color: #b0b0b0;
}

.headerButtons a.currentRoute {
    color: #4D66F3;
}

.centerButton {
    text-align: center;
}

.downloadButton {
    padding: 8px 16px;
    background-color: #4D66F3;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

@media (max-width: 768px) {
    header {
        flex-direction: column;
        align-items: center;
    }

    .logoAndTitleContainer {
        margin-bottom: 10px;
    }

    .headerButtons {
        flex-direction: column;
        align-items: center;
    }

    .headerButtons a {
        margin-bottom: 5px;
    }

    .downloadButton {
        display: none;
    }
}